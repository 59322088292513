import React from 'react'
import AttractionController from './AttractionController'

class Game extends React.Component  { 
	

	constructor() {
		super();
		this.canvasRef = React.createRef();
		this.contextSetup = false;
		this.imageLoaded = false;
	}	
	
	componentDidMount() {
      	this.canvasRef.current.width = window.innerWidth;
      	this.canvasRef.current.height = window.innerHeight;
      	this.controller = new AttractionController(this.canvasRef.current.width, this.canvasRef.current.height);
	
    	this.ctx = this.canvasRef.current.getContext('2d');

		this.animationID = window.requestAnimationFrame(() => this.update());  
		this.loadImage();
		window.addEventListener('resize', this.handleResize);
	}
	loadImage() {
	    // save to "this" to remove "load" handler on unmount
	    this.image = new window.Image();
	    this.image.src = "./all.png";
	    this.image.onload = () => {
	    	this.imageLoaded = true;
	    }
  	}

  	handleResize = () => {
  		console.log("Window resized");
      	this.canvasRef.current.width = window.innerWidth;
      	this.canvasRef.current.height = window.innerHeight;
      	this.controller.setSize(this.canvasRef.current.width, this.canvasRef.current.height);
  	}
  	
  	handleLoad = () => {
	    // after setState react-konva will update canvas and redraw the layer
	    // because "image" property is changed
	    this.setState({
	      image: this.image
	    });
	    // if you keep same image object during source updates
	    // you will have to update layer manually:
	    // this.imageNode.getLayer().batchDraw();
  	}

	update(timestamp) {

  		const delta = timestamp - this.start;
   		this.start = timestamp;
		this.controller.update(Math.floor(delta));
		this.draw();

		this.animationID = window.requestAnimationFrame((timestamp) => this.update(timestamp));

	}

	draw = () => {
	    this.ctx.fillStyle = '#000000';

   		this.grd = this.ctx.createLinearGradient(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
		this.grd.addColorStop(0, "#000015");
		this.grd.addColorStop(.5, "#000000");
		this.grd.addColorStop(1, "#000015");
		this.contextSetup = true;
		this.ctx.fillStyle = this.grd;
	    
	    this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
	    if(this.imageLoaded) {
		    this.controller.draw(this.ctx,this.image);
	    }

	}

	render() {
        return (
            <div>
                <canvas ref={this.canvasRef} width={450} height={650} />
            </div>
        );
    }
}

export default Game