import Attraction from '../components/Attraction'
import Config from '../config.js';

export async function loadAttractionsTest(two: false) {
		var ret = [];
		var parks = {"mk": "80007944"};

		let dataResponse = await fetch("./data.json");
		let data = await dataResponse.json();

		try {
			for(var park in parks) {
			    //let response = await fetch("http://localhost:3001/api/waittimes/" + parks[park]);
			    let response = await fetch("./times1.json");
			    if(two) response = await fetch("./times2.json");
			    let dataObj = await response.json();
		        for(var i=0; i<dataObj.length; i++) {
					var waitTime = dataObj[i].waitTime;
		        	if(dataObj[i].meta.type !== "RESTAURANT") {
		        		let idParts = dataObj[i].id.split("_");
		        		let dataForAttr = data[idParts[1]];
		        		let showTitle = false;
		        		if(!dataForAttr) {
		        			dataForAttr = data[park];
			        		showTitle = true;
		        			console.log("Didn't find id::" + dataObj[i].id + "::" + dataObj[i].name);
		        		}
		        		if(dataObj[i].status === "Down") {
		        			waitTime = "---";		        			
		        		}
		        		if(dataObj[i].status === "Refurbishment") {
		        			waitTime = "RFB";		        			
		        			dataObj[i].status = "Down";
		        		}
		        		var virtualQueue = false;
		        		if(dataObj[i].meta.boardingGroup) {
		        			if(dataObj[i].meta.boardingGroup.currentGroupEnd == null && dataObj[i].waitTime != null) {
			        			virtualQueue = false;
		        			} else {
		        				virtualQueue = true;
		        				waitTime = dataObj[i].meta.boardingGroup.currentGroupEnd;
		        			}
		        		}
		        		if(waitTime == null) waitTime = 0;
		        		ret.push(new Attraction(dataObj[i].name, 0, 0, waitTime, dataObj[i].id, dataObj[i].status, park, dataForAttr.x, dataForAttr.y, virtualQueue, showTitle));
		        	}
		        }
		    }
			return ret;
		  } catch (error) {
		  		console.log(error);
		      	ret.push(new Attraction("NOT FOUND", 0,0,"0"));
		      	return ret;
		  }
		}

export async function loadAttractions() {
		var ret = [];
		var parks = {"mk": "80007944", "ep":"80007838", "hs":"80007998", "ak":"80007823"};

		let dataResponse = await fetch("./data.json");
		let data = await dataResponse.json();
		try {
			for(var park in parks) {
			    let response = await fetch(Config.waittime_url + "/api/waittimes/" + parks[park]);
			    let dataObj = await response.json();
		        for(var i=0; i<dataObj.length; i++) {
				    var waitTime = dataObj[i].waitTime;
		        	if(dataObj[i].meta.type !== "RESTAURANT") {
		        		let idParts = dataObj[i].id.split("_");
		        		let dataForAttr = data[idParts[1]];
		        		let showTitle = false;
		        		if(!dataForAttr) {
							var foundByName = false;
							for(const obj in data) {
								if(data[obj].name === dataObj[i].name) {
									dataForAttr = data[obj];
									foundByName = true;
									break;
								}
							}
							if(!foundByName) {
								dataForAttr = data[park];
								showTitle = true;
								console.log("Didn't find id::" + dataObj[i].id + "::" + dataObj[i].name);
							}
		        		}
		        		if(dataObj[i].status === "Down") {
		        			waitTime = "---";		        			
		        		}
		        		if(dataObj[i].status === "Refurbishment") {
		        			waitTime = "RFB";		        			
		        			dataObj[i].status = "Down";
		        		}
		        		var virtualQueue = false;
		        		if(dataObj[i].meta.boardingGroup) {
		        			if(dataObj[i].meta.boardingGroup.currentGroupEnd == null && dataObj[i].waitTime != null) {
			        			virtualQueue = false;
		        			} else {
		        				virtualQueue = true;
		        				waitTime = dataObj[i].meta.boardingGroup.currentGroupEnd;
		        				if(!dataObj[i].meta.boardingGroup.currentGroupEnd)
		        					waitTime = "---";
		        			}
		        		}

						if(waitTime == null) {
							waitTime = 0;
							continue;
						}

		        		ret.push(new Attraction(dataObj[i].name, 0, 0, waitTime, dataObj[i].id, dataObj[i].status, park, dataForAttr.x, dataForAttr.y, virtualQueue, showTitle));
		        	}
		        }
		    }
			return ret;
		  } catch (error) {
		  		console.log(error);
		      	ret.push(new Attraction("NOT FOUND", 0,0,"0"));
		      	return ret;
		  }
		}
