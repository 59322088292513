import *  as AttractionService from './services/attractionService.js';
import Lane from './components/Lane';
import ZeroLane from './components/ZeroLane';
import VQLane from './components/VQLane';
import DownLane from './components/DownLane';
import SplashScreen from './components/SplashScreen';

class AttractionController { 
	LANE_COUNT = 9;
	LANE_TIMES = [1,10,30,50,70,90,1000]
	LANE_SIZE = [5,4,4,2,2,1];
	LANE_TOTAL = 18;
	loaded = false;

	constructor(w, h) {
		this.attr = [];
		this.splashTime = 0;

		this.width = w;
		this.height = h;
		this.NOWAIT_LANE = 0;
		this.QUEUE_LANE = 0;
		this.DOWN_LANE = 0;
		this.CLOSED_LANE = 0;
		this.createLanes();

		this.loadAttractions();
		setInterval(this.loadAttractions, 1000 * 60 * 5);
		//setInterval(this.loadAttractions, 10000);
		console.log(this.attr);
		this.lowTime = 999;
		this.highTime = -1;
		this.attrReady = false;
		this.splash = new SplashScreen(0,0,this.width, this.height);
		this.showSplash = true;
		this.fade = true;
		this.fadeTime = 0;
		this.fadeDuration = 1000;
	}
	
	createLanes = () => {
		this.lanes = [];
		var label;
		var low, high;
		var currentX = 0;
		for(var i = 0; i<this.LANE_TIMES.length-1; i++) {
			low = this.LANE_TIMES[i]; 
			high = this.LANE_TIMES[i + 1];
			
			if(i === this.LANE_TIMES.length-2) {
				label = low + "+";
			}
			this.lanes.push(new Lane(i, currentX, 0, this.width * this.LANE_SIZE[i] / this.LANE_TOTAL, this.height, low, high, label, this.LANE_SIZE[i]));
			currentX += this.width * this.LANE_SIZE[i] / this.LANE_TOTAL;
		}

		this.lanes.push(new ZeroLane(i++, 10, this.height - 110 - 10, 525, 110, 0, 0, "NO WAIT", "#002200"));
		this.NOWAIT_LANE = i-1;
		this.lanes.push(new VQLane(i++, 545+525+10, this.height - 110 - 10, 525, 110, -1, -1, "VIRTUAL QUEUE", "#333333"));
		this.QUEUE_LANE = i-1;
		this.lanes.push(new DownLane(i++, 545, this.height - 110 - 10, 525, 110, -1, -1, "DOWN"));
		this.DOWN_LANE = i-1;
		this.lanes.push(new DownLane(i++, 545, this.height + 110 - 10, 525, 110, -1, -1, "CLOSED"));
		this.CLOSED_LANE = i-1;

	}

	updateLaneSize = () => {
		var currentX = 0;
		for(var i = 0; i<this.LANE_TIMES.length-1; i++) {
			this.lanes[i].setSize(currentX, 0, this.width * this.LANE_SIZE[i] / this.LANE_TOTAL, this.height);
			currentX += this.width * this.LANE_SIZE[i] / this.LANE_TOTAL;
		}
		this.lanes[this.NOWAIT_LANE].setSize(10, this.height - 110 - 10, 525, 110);
		this.lanes[this.QUEUE_LANE].setSize(545+525+10, this.height - 110 - 10, 525, 110);
		this.lanes[this.DOWN_LANE].setSize(545, this.height - 110 - 10, 525, 110);
		this.lanes[this.CLOSED_LANE].setSize(545, this.height + 110 - 10, 525, 110);

	}

	update = (delta) => {
		if(this.showSplash) {
			this.showSplash = this.splash.update(delta);
			return;
		}

		if(this.fade) {
			this.updateFade(delta);
			return;
		}
		this.updateLanes(delta);
		for(var j=0; j< this.attr.length; j++) {
			this.attr[j].update(delta);
		}
	}
	updateLanes = (delta) => {
		for(var i=0; i<this.lanes.length; i++) {
			this.lanes[i].update(delta);
		}
	}

	updateFade = (delta) => {
		if(!this.fadeTime) this.fadeTime = 0;
		this.fadeTime += delta;
		if(this.fadeTime > this.fadeDuration) {
			this.fade = false;
		}
	}

	draw = (ctx, img) => {
		if(this.showSplash) {
			this.splash.draw(ctx, img);
			return;
		}
		for(var i=0; i<this.lanes.length; i++) {
			this.lanes[i].drawBG(ctx, img);
		}

		for(i=0; i<this.lanes.length; i++) {
			this.lanes[i].draw(ctx, img);
		}

		if(this.fade) {
			var alpha = 255 - Math.floor((this.fadeTime / this.fadeDuration) * 255);
			ctx.fillStyle = "#000000" + this.decimalToHex(alpha, 2);
			ctx.fillRect(0, 0, this.width, this.height);
		}
		
		ctx.font = '10px sans-serif';
	    ctx.fillStyle = "#FFFFFF22";
	    ctx.textAlign = "right";
		ctx.fillText("version 1.1.15 (" + process.env.NODE_ENV + ")", this.width-10, this.height-10);	

	/*	for(i=0; i<this.attr.length; i++) {   
	    	this.attr[i].draw(ctx,img);
	    }*/
	}
	
	setSize = (w,h) => {
		this.width = w;
		this.height = h;
		this.updateLaneSize();
	}
	
	findLane = (attr) => {
		if(attr.status === "Closed") {
			return this.lanes[this.CLOSED_LANE];
		}

		if(attr.status === "Down") {
			return this.lanes[this.DOWN_LANE];
		}
		if(attr.virtualQueue) {
			return this.lanes[this.QUEUE_LANE];
		}
		for(var j=0; j<this.lanes.length; j++) {
			if(attr.newWaitTime >= this.lanes[j].minWaitTime &&
			   attr.newWaitTime <= this.lanes[j].maxWaitTime) {
			   	return this.lanes[j];
			}
		}
	}

	loadAttractions = () => {
		console.log("Loading attractions");
		this.lowTime = 999;
		this.highTime = -1;

		if(this.attr.length === 0) {
			this.attr = AttractionService.loadAttractions()
						.then(attrs => {
							this.attr = attrs;
							this.loaded = true;

							var that = this;
							this.attr.forEach(function(item) {
								if(item.waitTime > that.highTime) that.highTime = item.waitTime;
								else if(item.waitTime < that.lowTime) that.lowTime = item.waitTime;
								item.changed = true;
								item.x = that.width / 2;
								item.y = that.height + 150;
								item.newWaitTime = item.waitTime;
								var lane = that.findLane(item);
								if(lane.maxWaitTime === 0) {
									item.setLane(lane, 0);
									//lane.addAttraction(item, 0);
								} else {
									item.setLane(lane, 5000);
									//lane.addAttraction(item, 5000);
								}
								
							});
						});
		} else {
			console.log("Updating attractions");
			AttractionService.loadAttractions(this.loaded)
				.then(attrs => {
					var found = false;
					this.loaded = !this.loaded;

					for(var i=0; i< attrs.length; i++) {
						if(attrs[i].waitTime > this.highTime) this.highTime = attrs[i].waitTime;
						else if(attrs[i].waitTime < this.lowTime) this.lowTime = attrs[i].waitTime;

						for(var j=0; j< this.attr.length; j++) {
							if(attrs[i].id === this.attr[j].id) {
								found = true;
								if(this.attr[j].waitTime !== attrs[i].waitTime || this.attr[j].status !== attrs[i].status) {
									this.attr[j].newWaitTime = attrs[i].waitTime;
									this.attr[j].changed = true;
									console.log(this.attr[j].name + " CHANGED");

									var changeLane = false;
									if(this.attr[j].status === "Closed" && attrs[i].status === "Operating") {
										changeLane = this.attr[j].changeLane(this.findLane(attrs[i]), 5000);
									} else if((this.attr[j].status === "Down" && attrs[i].status === "Operating") ||
											  (this.attr[j].status === "Operating" && attrs[i].status === "Down")	) {
										changeLane = this.attr[j].changeLane(this.findLane(attrs[i]), 0);										
									} else {
										changeLane = this.attr[j].changeLane(this.findLane(attrs[i]));
									}
									if(!changeLane) {
										this.attr[j].waitTime = attrs[i].waitTime;										
									}
									this.attr[j].status = attrs[i].status;
								} 
							}
						}
						if(!found) {
							if(attrs[i].waitTime > this.highTime) this.highTime = attrs[i].waitTime;
							else if(attrs[i].waitTime < this.lowTime) this.lowTime = attrs[i].waitTime;
							attrs[i].changed = true;
							attrs[i].x = this.width / 2;
							attrs[i].y = this.height + 150;
							attrs[i].newWaitTime = attrs[i].waitTime;
							var lane = this.findLane(attrs[i]);
							if(lane.maxWaitTime === 0) attrs[i].setLane(lane, 0);
							else attrs[i].setLane(lane, 5000);
							this.attr.push(attrs[i]);
						}
						found = false;
					}
					this.attrReady = true;
				});
		}
	}

	decimalToHex = (d, padding) => {
	    var hex = Number(d).toString(16);
	    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

	    while (hex.length < padding) {
	        hex = "0" + hex;
	    }

	    return hex;
	}
}

export default AttractionController