import Lane from './Lane';

class ZeroLane extends Lane {
	constructor(id, x, y, width, height, minWaitTime, maxWaitTime, label, bgColor) {
	  	super(id, x, y, width, height, minWaitTime, maxWaitTime, label);

	  	this.currentXDelta = 90;
		this.spots = [];
		this.deltaLimit = 4000;
		this.bgColor = bgColor;
	}

	update = (delta) => {
		if(!this.moveCount) this.moveCount = 0;
		this.moveCount = this.moveCount + delta;

		if(this.moveCount > this.deltaLimit && this.attrs.length >= 5) {
			this.moveCount = 0;
			this.attrs.push(this.attrs.shift());

			for(var i=0; i<this.attrs.length; i++) {
				this.attrs[i].changePos(this.getLocation(i), 0);
			}
		}
//		for(var i=0; i<this.attrs.length; i++) {
//			this.attrs[i].update(delta);
//		}

	}
	setSize = (x, y, width, height) => {
	  	this.x = x;
	  	this.y = y;
	  	this.width = width;
	  	this.height = height;
	  	for(var i=0; i<this.attrs.length; i++) {
			this.attrs[i].changePos(this.getLocation(i), 0);
		}
	}	
	addAttraction = (attr) => {
		attr.changePos(this.getLocation(this.attrs.length), 0);
		attr.animationMode = 2;
		this.attrs.push(attr);
	}

  	draw = (ctx, img) => {
		for(var i=this.attrs.length-1; i>=0; i--) {
			this.attrs[i].draw(ctx, img, false);
		}

	}
  	drawBG = (ctx, img) => {
		ctx.strokeStyle = '#ffffff';
		ctx.beginPath();
		ctx.rect(this.x, this.y, this.width, this.height);
		ctx.stroke();

		ctx.fillStyle = this.bgColor;
		ctx.fill();	

		ctx.font = '20px sans-serif';
	    ctx.fillStyle = "#FFFFFF22";
	    ctx.textAlign = "right";
		ctx.fillText(this.label, this.x+this.width-10, this.y+this.height-10);	
	    ctx.textAlign = "left";
		ctx.fillText(this.label, this.x+10, this.y+25);	
		
	    //ctx.fillStyle = "#999999";
	    //ctx.textAlign = "center";
		//ctx.fillText(this.label, this.x+this.width/2, this.y-5);	

	}
	
	setLocation = (idx) => {
		var ret = this.getLocation(idx);
		return ret;
	}

	getLocation = (idx) => {
		var x = 0;
		var y = 0;
		
		if(idx < 3) {
			x = this.x + 470 - (idx * 105);
			y = this.y + this.height/2;
		} else if(idx >= 3 && idx < 8) {
			x = this.x + 230 - (idx * 25);
			y = this.y + this.height/2;
		} else {
			x = this.x + 60;
			y = this.y + this.height/2;			
		}
		return {x:x, y:y};
	}

}

export default ZeroLane