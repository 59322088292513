import ZeroLane from './ZeroLane';

class VQLane extends ZeroLane {
  	draw = (ctx, img) => {
		for(var i=this.attrs.length-1; i>=0; i--) {
			this.attrs[i].draw(ctx, img, true);
		}

	}

}

export default VQLane