import ZeroLane from './ZeroLane';

class DownLane extends ZeroLane {
  	draw = (ctx, img) => {
		for(var i=this.attrs.length-1; i>=0; i--) {
			this.attrs[i].draw(ctx, img);
		}


	}
  	drawBG = (ctx, img) => {

		ctx.strokeStyle = '#ffffff';
		ctx.beginPath();
		ctx.rect(this.x, this.y, this.width, this.height);
		ctx.stroke();

		ctx.fillStyle = '#000000';
		ctx.fill();		

		ctx.font = '20px sans-serif';

		ctx.rect(this.x, this.y, this.width, this.height);
		ctx.stroke();

		ctx.fillStyle = '#FF000044';
		ctx.fill();		

	    ctx.fillStyle = "#FFFFFF44";
	    ctx.textAlign = "right";
		ctx.fillText(this.label, this.x+this.width-10, this.y+this.height-10);	
	    ctx.textAlign = "left";
		ctx.fillText(this.label, this.x+10, this.y+25);	

	    //ctx.fillStyle = "#999999";
	    //ctx.textAlign = "center";
		//ctx.fillText(this.label, this.x+this.width/2, this.y-5);	
	}
}

export default DownLane