const dev = {
  waittime_url: "http://localhost:3000"
};

const prod = {
  waittime_url: "http://disparksnow.com"
};

const Config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default Config