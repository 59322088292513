class SplashScreen {
	constructor(x, y, width, height) {
	  	this.x = x;
	  	this.y = y;
	  	this.width = width;
	  	this.height = height;
	  	this.time = 0;
	  	this.splashDuration = 3000;
	  	this.fadeDuration = 2000;
	  	this.splash = true;
	  	this.fade = false;
	  	this.ctxSetup = false;
	}
	
  	draw = (ctx, img) => {
  		if(!this.ctxSetup) {
  			this.ctxSetup = true;  			
  		}
		ctx.font = '60px sans-serif';
		ctx.fillStyle = '#FFFFFF';
		ctx.textAlign = "center";
		ctx.fillText("disparksnow.com", this.width / 2, this.height / 2);

		if(this.fade) {
			var alpha = Math.floor((this.time / this.fadeDuration) * 255);
			ctx.fillStyle = "#000000" + this.decimalToHex(alpha, 2);
			ctx.fillRect(0, 0, this.width, this.height);
		}
	}

	update = (delta) => {
		if(!this.time) this.time = delta;
		this.time = this.time + 0 + parseInt(delta);
		if(this.splash && this.time > this.splashDuration) {
			this.splash = false;
			this.fade = true;
			this.time = 0;
		}
		if(this.fade && this.time > this.fadeDuration) {
			this.fade = false;
			return false;
		}
		return true;
	}

	decimalToHex = (d, padding) => {
	    var hex = Number(d).toString(16);
	    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

	    while (hex.length < padding) {
	        hex = "0" + hex;
	    }

	    return hex;
	}
}

export default SplashScreen