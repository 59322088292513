class Lane {
	constructor(id, x, y, width, height, minWaitTime, maxWaitTime, label, columns) {
	  	this.id = id;
	  	this.x = x;
	  	this.y = y;
	  	this.width = width;
	  	this.height = height;
	  	this.label = label;
	  	this.minWaitTime = minWaitTime;
	  	this.maxWaitTime = maxWaitTime;
	  	this.currentXDelta = 90;
		this.spots = [];
		this.attrs = [];
		this.moveCount =0;
		this.labelX = this.x + (this.width/2);
		this.startX = this.x + this.width/2 - (columns * 35);
		this.contextSetup = false;
		this.columns = columns;
		this.backgroundCircles = [];
		for(var i=0; i<50; i++) {
			this.backgroundCircles.push(this.getLocation(i));
		}
	}

  	parkColors = {"mk": "#8d93ff", "ep": "#8c8e8d", "hs":"#ab853c","ak":"#61b065"}
  	laneColors = ["#002200", "#001600","#001100","#111100","#161600","#222200",];
	
	setupCtx = (ctx) => {
		this.grd = ctx.createLinearGradient(this.x, 0, this.x+this.width, 0);
		this.grd.addColorStop(0, "#000000");
		this.grd.addColorStop(.5, this.laneColors[this.id]);
		this.grd.addColorStop(1, "#000000");
		this.contextSetup = true;
	}

	setSize = (x, y, width, height) => {
	  	this.x = x;
	  	this.y = y;
	  	this.width = width;
	  	this.height = height;
		this.startX = this.x + this.width/2 - (this.columns * 35);
		this.contextSetup = false;
		this.labelX = this.x + (this.width/2);
	  	for(var i=0; i<this.attrs.length; i++) {
			this.attrs[i].changePos(this.getLocation(i), 0);
		}
		this.backgroundCircles = [];
		for(i=0; i<50; i++) {
			this.backgroundCircles.push(this.getLocation(i));
		}


	}	
  	draw = (ctx, img) => {
		for(var i=0; i<this.attrs.length; i++) {
			this.attrs[i].draw(ctx, img);
		}	
	}
  	drawBG = (ctx, img) => {
  		if(!this.contextSetup) this.setupCtx(ctx);

		ctx.strokeStyle = '#ffffff';
		ctx.beginPath();

		//ctx.fillStyle = this.grd;
		//ctx.fillRect(this.x, this.y, this.width, this.height);

		ctx.font = '30px sans-serif';
		ctx.fillStyle = "#999999";
	    ctx.textAlign = "center";

		if(this.label) {
			ctx.fillText(this.label, this.labelX, 22);
		} else {
			ctx.fillText(this.minWaitTime + " - " + this.maxWaitTime, this.labelX, 24);
		}

		ctx.beginPath();

		var strokeSt = "#333333";
		ctx.strokeStyle = strokeSt;
		for(var i=0; i<this.backgroundCircles.length; i++) {
			ctx.moveTo(this.backgroundCircles[i].x+50, this.backgroundCircles[i].y);
			ctx.arc(this.backgroundCircles[i].x, this.backgroundCircles[i].y, 50, 0, 2 * Math.PI);
		}
		ctx.stroke();		
	}
	update = (delta) => {
//		for(var i=0; i<this.attrs.length; i++) {
//			this.attrs[i].update(delta);
//		}

	}

	addAttraction = (attr, delay = 0) => {
		attr.changePos(this.getLocation(this.attrs.length), delay);
		this.attrs.push(attr);
	}

	removeAttraction = (attr) => {
		var i=0;
		for(i=0; i<this.attrs.length; i++) {
			if(attr.id === this.attrs[i].id) {
				this.attrs.splice(i, 1);
				break;
			}
		}
		for(i; i<this.attrs.length; i++) {
			this.attrs[i].changePos(this.getLocation(i), 0);
		}

	}

	getLocation = (idx) => {
		var x = 0;
		var y = 0;

		switch(this.columns) {
		  	case 2:
				x = this.x + this.width/2 - 52 + (104 * (idx % 2));
				y = 104 * Math.floor(idx/2) + 80;
			    break;
		  	case 3:
				if(idx % 3 === 0) {
					x = this.startX;
					y = 104 * (Math.floor(idx/3)) + 70;
				} else if(idx % 3 === 1) {
					x = this.startX + this.currentXDelta;
					y = 104 * (Math.floor(idx/3)) + 122;
				} else {
					x = this.startX + (this.currentXDelta *2);
					y = 104 * (Math.floor(idx/3)) + 70;
				}
			    break;
		  	case 4:
				if(idx % 4 === 1 || idx % 4 === 2) {
					x = this.startX + ((idx % 4) * this.currentXDelta) + (14 *((idx % 4)-1));
					y = 104 * (Math.floor(idx/4)) + 80;
				} else if(idx % 4 === 0 || idx % 4 === 3) {
					x = this.startX + (this.currentXDelta * (idx % 4)) + (4.8 *((idx % 4)));
					y = 104 * (Math.floor(idx/4)) + 132;
				} 
			    break;
		  	case 5:
				if(idx % 5 === 1 || idx % 5 === 3) {
					x = this.startX + ((idx % 5) * this.currentXDelta);
					y = 104 * (Math.floor(idx/5)) + 80;
				} else if(idx % 5 === 0 || idx % 5 === 2 || idx % 5 === 4) {
					x = this.startX + (this.currentXDelta * (idx % 5));
					y = 104 * (Math.floor(idx/5)) + 132;
				} 
			    break;
			default:
				x = this.x + this.width/2;
				y = 104 * idx + 80;

		}
		return {x:x, y:y};
	}

}

export default Lane