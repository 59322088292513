import *  as Constants from '../constants.js';

class Attraction {
	constructor(name, x, y, waitTime, id, status, park, imgX, imgY, virtualQueue, showTitle) {
	  	this.name = name;
	  	this.x = x;
	  	this.y = y;
	  	this.waitTime = waitTime;
	  	this.newWaitTime = waitTime;
	  	this.id = id;
	  	this.changed = false;
	  	this.status = status;
	  	this.park = park;
	  	this.setupCtx = false;
	  	this.titleSize = 20;
	  	this.imgX = imgX;
	  	this.imgY = imgY;
	  	this.virtualQueue = virtualQueue;
	  	this.delay = 0;
	  	this.delayCounter = 0;
	  	this.moving = Constants.STOPPED;
	  	this.changingLanes = false;
	  	this.closing = false;
	  	this.showTitle = showTitle;
	  	this.animationFrame = 0;
	  	this.animationLength = 2000;
	  	this.animationMode = 1;
	  	this.dx = 0;
	  	this.dy = 0;

	  	this.count = -1;
	}

  	parkColors = {"mk": "#8d93ff", "ep": "#8c8e8d", "hs":"#ab853c","ak":"#61b065"}
	
	setupContext(ctx) {
		this.setupCtx = true;
	    ctx.font = this.titleSize + 'px serif';
		var w = ctx.measureText(this.name).width;
		while(w > 100) {
			this.titleSize -= 2;
		    ctx.font = this.titleSize + 'px serif';
			w = ctx.measureText(this.name).width;
		}
	}

	update = (delta) => {
		if(this.moving === Constants.WAITING_TO_MOVE) {
			this.delayCounter += delta;
			if(this.delayCounter > this.delay) {
				this.moving = Constants.MOVING;
				if(this.changingLanes) {
					if(this.lane) this.lane.removeAttraction(this);
					this.newLane.addAttraction(this, 0);
					this.lane = this.newLane;
				  	this.changingLanes = false;
				}
			}
		} else if(this.moving === Constants.MOVING) {
			this.move(delta);
		}
	}

	move = (delta) => {
		if(this.moving === Constants.MOVING) {
			this.animationFrame += delta;
			this.x = this.dx * this.ease(this.animationFrame / this.animationLength) + this.startX;
			this.y = this.dy * this.ease(this.animationFrame / this.animationLength) + this.startY;
			if(this.animationFrame > this.animationLength) {
				this.moving = Constants.STOPPED;
				this.x = this.newX;
				this.y = this.newY;
				this.waitTime = this.newWaitTime;
				this.animationFrame = 0;
			  	this.delayCounter = 0;
			}
		}
	}
	
	ease = (x) => {
		switch(this.animationMode) {
		 	case 1:
		    	return this.easeOutQuint(x);
		  	case 2:
		    	return this.easeInOutCubic(x);
		  	case 3:
		    	return this.easeOutBack(x);
		  	case 4:
		    	return this.easeOutElastic(x);
		    case 5:
				return this.easeInBack(x);
		  	default:
		    	return this.easeOutQuint(x);
		}
	}

	easeOutBack = (x) => {
		const c1 = 1.70158;
		const c3 = c1 + 1;

		return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
	}
	easeInOutCubic = (x) => {
		return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
	}
	easeOutQuint = (x) => {
		return 1 - Math.pow(1 - x, 5);
	}
	easeOutElastic = (x) => {
		const c4 = (2 * Math.PI) / 3;

		return x === 0
		  ? 0
		  : x === 1
		  ? 1
		  : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
	}
	
	easeInBack = (x) => {
		const c1 = 1.70158;
		const c3 = c1 + 1;

		return c3 * x * x * x - c1 * x * x;
	}
	
	checkChangeLane = (laneId) => {
		if(this.laneId === laneId) return false;
		return true;
	}

	setLane = (lane, delay = 0) => {
		this.newLane = lane;
		this.lane = lane;
		this.moving = Constants.WAITING_TO_MOVE;
		this.delay = Math.random() * delay;
	  	this.delayCounter = 0;
	  	this.animationFrame = 0;
	  	this.changingLanes = true;
	}

	changeLane = (newLane, delay = -1) => {
		this.newLane = newLane;
		if(!this.lane || newLane.id !== this.lane.id) {
			this.moving = Constants.WAITING_TO_MOVE;
			if(delay === -1) 
				this.delay = Math.random() * Constants.REFRESH_TIME;
			else 
				this.delay = Math.random() * delay;
		  	this.delayCounter = 0;
		  	this.animationFrame = 0;
		  	this.changingLanes = true;
		  	if(!this.lane) this.lane = newLane;
		  	return true;
		}
		return false;
	}

/*	changeLane = (newLane, pos, force = false) => {
		if(this.lane.id !== newLane.id || force) {
			this.lane = newLane;
			this.animationFrame = 0;
			this.newX = pos.x;
			this.newY = pos.y;
			this.startX = this.x;
			this.startY = this.y;
			this.dx = (this.newX - this.startX);
			this.dy = (this.newY - this.startY);
		}
	}
*/
	changePos = (pos, delay = -1) => {
		this.newX = pos.x;
		this.newY = pos.y;
		this.startX = this.x;
		this.startY = this.y;
		this.dx = (this.newX - this.startX);
		this.dy = (this.newY - this.startY);
		if(delay === -1) 
			this.delay = Math.random() * Constants.REFRESH_TIME;
		else 
			this.delay = Math.random() * delay;
	  	this.delayCounter = 0;
	  	this.animationFrame = 0;
		this.moving = Constants.WAITING_TO_MOVE;
//		this.changingLanes = false;
	}

	close = (height) => {
		this.newY = height;
		this.startX = this.x;
		this.startY = this.y;
		this.dx = (this.newX - this.startX);
		this.dy = (this.newY - this.startY);
		this.delay = 0;
	  	this.delayCounter = 0;
	  	this.animationFrame = 0;
		this.moving = Constants.WAITING_TO_MOVE;
		this.closing = true;
		this.lane.removeAttraction(this);
		this.lane = null;
	}

	changePos2 = (pos, delay = -1) => {
		this.animationFrame = 0;
		this.newX = pos.x;
		this.newY = pos.y;
		this.startX = this.x;
		this.startY = this.y;
		this.dx = (this.newX - this.startX);
		this.dy = (this.newY - this.startY);
		if(delay === -1) 
			this.delay = Math.random() * Constants.REFRESH_TIME;
		else 
			this.delay = Math.random() * delay;
	  	this.delayCounter = 0;
		this.moving = Constants.WAITING_TO_MOVE;
	}

  	draw = (ctx, img, showWaitTime = true) => {

		//if(!this.setupCtx) this.setupContext(ctx);
  		ctx.drawImage(
				    // Image
				    img,
				    // ---- Selection ----
				    this.imgX, // sx
				    this.imgY, // sy
				    100, // sWidth
				    100, // sHeight
				    // ---- Drawing ----
				    this.x - 50, // dx
				    this.y - 50, // dy
				    100, // dWidth
				    100 // dHeight
				  );

	    if(showWaitTime) {
	    	ctx.font = '20px sans-serif';
	    	ctx.fillStyle = '#FFFFFF';
	    	ctx.textAlign = "center";
			ctx.fillText(this.waitTime, this.x+23, this.y+10);
		}

		if(this.showTitle) {
		    ctx.font = '20px sans-serif';
		    ctx.fillStyle = '#FFFFFF';
		    ctx.textAlign = "center";
			ctx.fillText(this.name, this.x+3, this.y+50);		
		}
  	}

  	setPosition = (x,y) => {
  		this.x = x;
  		this.y = y;
  	}
}

export default Attraction